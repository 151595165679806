import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Header from "../components/header"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Header />

    <section className="intro hero">
      <div className="hero-body">
        <div className="container" style={{ maxWidth: 800 }}>
          <h1 className="title">Hi, I'm Francisco.</h1>
          <br />
          <h2 className="subtitle is-4 mt-4">
            Some say I have <b>mixed beliefs:</b>
          </h2>

          <ul>
            <li>
              I believe in long-term investing: I love working with beautiful
              architecture, following best practices, and testing what I do.
            </li>
            <li>
              I believe in speed, fast releasing, no bureaucracy, and fast
              pivoting: There is no value if there are no users.
            </li>
          </ul>

          <h3 className="is-size-4">
            <b>I say: you can have them both.</b>
          </h3>

          <hr />

          <h3 className="is-size-4 has-text-weight-semibold">
            Interested in working with me?
          </h3>

          <p className="contact">
            <a href="mailto:hi@franciscovg.com">
              <strong>Email me</strong>
            </a>
          </p>

          <p className="contact">
            <a href="https://www.linkedin.com/in/hola-francisco/">
              <strong>LinkedIn</strong>
            </a>
          </p>

          <p className="contact">
            <a href="https://github.com/franvera">
              <strong> GitHub</strong>
            </a>
          </p>
        </div>
      </div>
    </section>
    {/* 
    <section className="section">
      <div className="container">
        <p className="title">Recent work</p>
        <div className="columns is-multiline is-centered is-6 is-variable">
          {allProjects.map(project => (
            <div className="column is-two-fifths" key={project.name}>
              <Project {...project} />
            </div>
          ))}
        </div>
      </div>
    </section>

    <section className="section">
      <div className="container">
        <p className="title">Hire me</p>
        <p className="subtitle">
          Looking for an experienced developer?
          <br />
          I'd love to help in a professional, communicative and punctual manner.
          <br />
          Send me an email: <EmailMe />
        </p>
      </div>
    </section> */}
  </Layout>
)

export default IndexPage

import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";

const EmailMe = ({ icon = true }) => (
  <a href="mailto:hi@franciscovg.com">
    {icon ? <FontAwesomeIcon icon={faEnvelope} size="lg" style={{
      maxHeight: 27, // Fix jumping image in production
    }} /> : ""}
    <strong> hi@franciscovg.com</strong>
  </a>
)

export default EmailMe;